import './bootstrap';
import '../css/app.css';

import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

import '@vueform/multiselect/themes/default.css';
import {CkeditorPlugin} from "@ckeditor/ckeditor5-vue";
import moment from "moment";
import 'moment/locale/ro';
import {plugin as VueTippy} from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import * as Sentry from "@sentry/vue"; // optional for styling

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {

        let app = createApp({render: () => h(App, props)})
            .use(plugin)
            .use(VueTippy, {
                directive: 'tippy',
                component: 'tippy',
                defaultProps: {
                    allowHTML: true,
                }
            })
            .use(CkeditorPlugin)
            .use(ZiggyVue)

        app.config.globalProperties.$moment = moment;

        //turn the app_url env variable into regex string
        let url = import.meta.env.VITE_APP_URL.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

        Sentry.init({
            app,
            dsn: "https://d9993132922127d1f73f968b871ae4d5@sentry.webarch.ro/4",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            tracePropagationTargets: ["localhost", url],
            replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

        });

        return app.mount(el);

    },
    progress: {
        color: '#4B5563',
    },
});
